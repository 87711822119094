// collection layout
.collection {
    text-align: center;
}
.summary {
    display: block;
    margin: $medium-gap 0;
    text-align: left;

    &-header { margin-bottom: $narrow-gap; }
    &-title {
        display: inline;
        font-family: $text-font-family;
        font-size: 1em;
        font-weight: bold;
    }
    &-date {
        display: inline;
        color: $info-color;
        line-height: $text-line-height;
    }
    &-content p { margin: 0; }
}
.preview {
    display: inline-block;
    margin: $medium-gap;
    text-align: left;
    text-decoration: none;
    vertical-align: middle;
    color: $text-color;

    &-canvas {
        overflow: hidden;
        background: url("tile-bg.gif") $bg-color;
    }
    &-type {
        &-text { padding: $medium-gap; }
    }
    &-weight {
        &-1 {
            max-width: 160px;
            max-height: 160px;
            font-size: 80%;
        }
        &-2 {
            max-width: 180px;
            max-height: 180px;
            font-size: 90%;
        }
        &-3 {
            max-width: 200px;
            max-height: 200px;
            font-size: 100%;
        }
    }

    &-header { margin-bottom: $medium-gap; }
    &-date {
        color: $info-color;
        line-height: $text-line-height;
    }
    &-title { font-size: 1.5em; }
    &-excerpt {
        margin: 0;
        line-height: 1.0;
    }
}
