
// site layout
.base {
    position: relative;
    min-height: 100%; // fill viewport on short pages

    &-top-left, &-top-right,
    &-bottom-left, &-bottom-right {
        position: absolute;
        max-width: 40%;
    }

    &-top-left { top: 0; left: 0; }
    &-top-right { top: 0; right: 0; }
    &-bottom-left { bottom: 0; left: 0; }
    &-bottom-right { bottom: 0; right: 0; }
}
.header {
    max-width: $column-width;
    margin: 0 auto;
    padding: $medium-gap;

    &-banner, &-links {
        position: relative; // position in front of corner accents
    }

    &-banner { text-align: center; }
    &-text { font-size: 1.75em; }
    &-line { display: inline-block; }
    &-title { margin: $narrow-gap 0 $medium-gap; }
    &-image { max-width: 100%; }

    &-links { text-align: center; }
    &-linkset { display: inline; }
    &-link {
        display: inline-block;

        font-family: $heading-font-family;
        font-size: 1.25em;
        text-decoration: none;

        margin: 0 $narrow-gap $narrow-gap;
    }
    &-icon { margin-right: $narrow-gap; }
}
.body {
    $border-height: 22px; // height of border.gif

    background: url("inner-bg.gif") top center $bg-color;
    box-shadow:
        inset 0 $border-height $shadow-blur (-$shadow-blur) $shadow-color,
        inset 0 (-$border-height/2) $shadow-blur (-$shadow-blur) $shadow-color;

    &-content {
        position: relative; // position in front of corner accents
        max-width: $column-width;
        margin: 0 auto;
        padding: 0 $medium-gap;
    }

    &-border-top, &-border-bottom {
        height: $border-height;
        margin: 0;
        padding: 0;
        border: 0;
        background: url("border.gif") top center;
        background-repeat: repeat-x;
    }
    &-border-bottom {
        position: relative;
        top: $border-height;
        z-index: -1;
        margin: -$border-height 0 $border-height;
    }
}
.footer {
    max-width: $column-width;
    margin: 0 auto;
    padding: $medium-gap;
    color: $info-color;
    text-align: center;

    &-notice, &-badges {
        position: relative; // position in front of corner accents
    }

    &-notice {
        margin-bottom: $medium-gap;
        line-height: $text-line-height;
    }
    &-badges { margin-bottom: $medium-gap; }
    &-badge {
        display: inline-block;
        margin: $narrow-gap;
    }

    &-counter {
        white-space: nowrap;
    }
}
