.premier {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: $medium-gap auto;

    &-accent { flex: 0 1.25 auto; }
    &-accent-image-left, &-accent-image-right {
        max-width: 100%;
    }
    &-accent-image-right { transform: scaleX(-1); }

    &-content {
        flex: 0 1 auto;
        margin: 0 $medium-gap;
    }
    &-media {
        max-width: 480px;
    }

    &-title {
        margin-top: $narrow-gap;
        text-align: center;
        font-size: 1.25em;
    }
}
