// post layout
.post {
    &-media { text-align: center; }

    // footer
    &-materials, &-nav {
        margin: $medium-gap 0;
    }
    &-material { margin: $narrow-gap; }
    &-button {
        display: inline-block;
        min-width: 150px;
    }
}
