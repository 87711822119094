// this has no corresponding include - just use a div with class "frame-*"

.frame {
    &-small-1, &-small-2, &-small-3, &-large {
        position: relative;
        display: inline-block;
        box-shadow: 0 0 $shadow-blur $shadow-color;

        &::after {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            pointer-events: none; // enable click-through
            box-shadow: inset 0 0 $shadow-blur $shadow-color;
        }

    }

    &-small-1 { @include frame("frames/small-1.gif", 5, stretch, $bg-color); }
    &-small-2 { @include frame("frames/small-2.gif", 16, stretch, $bg-color); }
    &-small-3 { @include frame("frames/small-3.gif", 23, stretch ,$bg-color); }

    &-large {
        @include frame("frames/large.gif", 25, round, $bg-color);

        @media #{$small-media-query} {
            border-width: 15px;
            border-image-width: 15px;
        }
    }
}
