.document {
    margin: $wide-gap 0;

    &-header, &-footer {
        margin: $medium-gap 0;
        text-align: center;
    }

    // header
    &-heading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-accent {
        margin: 0 $medium-gap;
    }
    &-info {
        line-height: $text-line-height;
        color: $info-color;
    }
}
