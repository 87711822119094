@font-face {
    font-family: "Chomsky";
    src: url("chomsky.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Crimson";
    src: url("crimson.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

html { height: 100%; }
body {
    color: $text-color;
    background: url("outer-bg.gif") top center $bg-color;
    font-family: $text-font-family;
    font-size: $text-font-size;
    line-height: 1.0;

    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
}


// headings
h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1em; }
h5 { font-size: 0.83em; }
h6 { font-size: 0.75em; }

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    font-family: $heading-font-family;
    font-weight: normal;
}
h1 { margin: 0; }
h2, h3, h4, h5, h5 {
    text-align: center;
    margin: $wide-gap 0 $medium-gap;
}


// text elements
p, ol, ul, pre, blockquote {
    line-height: $text-line-height;
}
p, ol, ul, pre, blockquote {
    margin-top: $medium-gap;
    margin-bottom: $medium-gap;
}
ul, ol {
    padding-left: $wide-gap;
}
blockquote {
    margin-left: $wide-gap;
    margin-right: $wide-gap;
}
a {
    color: $text-color;
    text-decoration: underline;
}
hr {
    width: 100%;
    max-width: 635px;
    height: 20px;
    margin: 0 auto;
    padding: 0;
    border: 0;
    background: url("hr.gif");
    background-position: center;
}

::selection {
    color: $bg-color;
    background: $text-color;
}
