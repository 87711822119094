// colors
$text-color: #ff1f1f;
$info-color: #ee1f1f;
$bg-color: #000000;
$tile-color: #333333;

// fonts
$heading-font-family: "Chomsky", serif;
$text-font-family: "Crimson", serif;
$text-font-size: 15pt;
$text-line-height: 1.2;

// gaps
$narrow-gap: 6px;
$medium-gap: 16px;
$wide-gap: 28px;

// shadows
$shadow-color: #000000;
$shadow-blur: 8px;

// media queries
$column-width: 1200px;
$small-media-width: 480px;
$small-media-query: "only screen and (max-width: #{$small-media-width})";
